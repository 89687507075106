<template>
  <router-link
    v-if="proData"
    :to="`/prodetail?id=${proData.id}`"
    class="store-item column-center"
  >
    <el-image :src="proData.image" fit="cover" class="image" lazy></el-image>
    <p class="row-center">
      <make-priceformat
        :price="proData.min_price"
        class="mr20"
        :subscriptSize="18"
        :firstSize="18"
        :secondSize="18"
        color="#333333"
      ></make-priceformat>
    </p>
    <div class="item-title line1 lighter xs">
      {{ proData.name }}
    </div>
  </router-link>
</template>
<script>
export default {
  name: "",
  props: {
    //单品信息
    proData: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.store-item {
  width: 178px;
  margin: 0 auto;
  .image {
    width: 136px;
    height: 136px;
    margin-bottom: 15px;
  }
  .item-title {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
